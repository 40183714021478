import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  FormControl,
  Button,
  Select,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import moment from "moment";
import theMartLogo from "../../assets/images/theMart_logo_new.png";
import { DataGrid, GridRow } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const SaturdayData = () => {
  const url = process.env.REACT_APP_API;
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [year, setYear] = useState(2023);
  const [dateRecords, setDateRecords] = useState([]);
  const [years, setYears] = useState([
    2019, 2020, 2021, 2022, 2023, 2024, 2025,
  ]);

  useEffect(() => {
    getDataByYear(new Date(year, 0, 1), new Date(year, 11, 31, 23, 59, 59));
  }, [year]);

  const getDataByYear = async (startDate, endDate) => {
    try {
      const response = await axios.get(
        `${url}/customRangeData?startDate="${startDate}"&endDate="${endDate}`
      );
      setData(response.data);
      setDateRecords(response.data.date_records);
    } catch (error) {
      //console.log(error);
    }
  };


  const lobbyGridColumns = [
    { field: "date", headerName: "Date", width: 150 },
    { field: "day", headerName: "Day", width: 200 },
    { field: "dateWiseSum", headerName: "People Visited", width: 200 },
  ];

  const renderAccordion = () => {
    const saturdaysData = dateRecords.filter((item) => item.day === "Saturday");
    console.log(saturdaysData);
    const updatedResponses = saturdaysData.map(response => {
        const ctaCount = response.data.find(item => item.lobby_name === 'CTA').count;
        const newDateWiseSum = response.dateWiseSum - parseInt(ctaCount.replace(/,/g, ''), 10);
      
        return {
          ...response,
          dateWiseSum: newDateWiseSum
        };
      });
    return(
    <div style={{ height: 800, width: "100%", background:"white" }}>
      <DataGrid
        rows={updatedResponses.map((d, index) => {
          return { ...d, id: index };
        })}
        columns={lobbyGridColumns}
        autoHide={true}
      />
    </div>)
  };

  const handleYearChange = (e) => {
    setYear(Number(e.target.value));
  };

  return (
    <>
      <div className="p-3 text-center">
        <img className="logo" src={theMartLogo} />
      </div>
      <Grid container>
        <Grid item md={12} xs={12} lg={12}>
          <div className="p-3">
            <h2>Pedestrian Counts Dashboard</h2>
          </div>
        </Grid>
      </Grid>
      {/* Your other JSX code */}
      <div className="p-4">
        <Grid container>
          <Grid item md={4} xs={12} lg={6}>
            <div>
              <label>Select year:</label>
              <label className="ml-1">
                <Select
                  native
                  value={year}
                  onChange={handleYearChange}
                  inputProps={{
                    name: "WeekendingDates",
                    id: "age-native-simple",
                  }}
                  className="ml-2"
                >
                  {years.map((dt) => {
                    return (
                      <option key={dt} value={dt}>
                        {dt}
                      </option>
                    );
                  })}
                </Select>
              </label>
              {/* Your other JSX code */}
            </div>
          </Grid>
        </Grid>
        {!data ? (
          <div></div>
        ) : (
          <div className="mt-5">
            <Grid container spacing={5}>
              <Grid item md={6} xs={12} lg={6}>
                <div className={classes.root}>
                  <div>
                    <Typography variant="h5" className="text-center mb-4">
                      Saturday's Data
                    </Typography>
                    <div>{renderAccordion()}</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
};

export default SaturdayData;